divdivdivdivdivdiv
<template>
  <div>
    <h6>Buat Realisasi</h6>

    <br />
    <v-form ref="initialForm" @submit.prevent="handleSubmit">
      <div class="max-input">
        <label class="fs-14 mb-2">Komoditas</label>
        <v-autocomplete
          :items="komoditas"
          item-text="uraian"
          item-value="seri"
          @change="handleChangeKomoditas"
          outlined
          dense
          :rules="[
            (value) => {
              return genericRequiredRule(value, 'Komoditas');
            },
          ]"
        />
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <table class="fs-14 lh-lg">
                <tr
                  v-for="(item, i) in [
                    {
                      key: 'No.Seri',
                      value: form_komoditas.seri,
                    },
                    {
                      key: 'HS Code',
                      value: form_komoditas.hs,
                    },
                    {
                      key: 'Negara Asal',
                      value: form_komoditas.negara_asal,
                    },
                    {
                      key: 'Pelabuhan Tujuan',
                      value: form_komoditas.pelabuhan_tujuan,
                    },
                    {
                      key: 'Keterangan',
                      value: form_komoditas.uraian,
                    },
                    {
                      key: 'Harga Satuan',
                      value: form_komoditas.harga_satuan + ' ' + form_komoditas.kode_kurs,
                    },
                    {
                      key: 'Total Harga',
                      value: form_komoditas.totalharga,
                    },
                  ]"
                  :key="i"
                >
                  <td style="min-width: 170px">{{ item.key }}</td>
                  <td style="min-width: 20px">:</td>
                  <td>{{ item.value }}</td>
                </tr>
              </table>
            </div>
            <div class="col-md-6">
              <table class="fs-14 lh-lg">
                <tr
                  v-for="(item, i) in [
                    {
                      key: 'Kuota',
                      value: form_komoditas.kuota + ' ' + form_komoditas.satuan,
                    },
                    {
                      key: 'Kuota Terkecil',
                      value: form_komoditas.kuota_terkecil + ' ' + form_komoditas.satuan_terkecil,
                    },
                    {
                      key: 'Sisa Kuota',
                      value: check_stock.sisa_stock,
                    },
                    {
                      key: 'Sisa Kuota Terkecil',
                      value: check_stock.sisa_stock_terkecil,
                    },
                  ]"
                  :key="i"
                >
                  <td class="fw-medium" style="min-width: 170px">
                    {{ item.key }}
                  </td>
                  <td style="min-width: 20px">:</td>
                  <td>{{ item.value }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="" style="max-width: 1200px">
        <div class="row">
          <div class="col-md-6">
            <label class="fs-14 mb-2">Realisasi Volume (Satuan Terbesar)</label>
            <v-text-field
              outlined
              dense
              v-model="form_komoditas.volume"
              @change="calculateVolumeTerkecil"
              @input="calculateVolumeTerkecil"
              :suffix="form_komoditas.satuan"
              :rules="[
                (value) => {
                  return genericRequiredRule(value, 'Realisasi Volume (Satuan Terbesar)');
                },
                (value) => {
                  return this.handleCheckVolume(value);
                },
              ]"
            />
          </div>
          <div class="col-md-6">
            <label class="fs-14 mb-2">Realisasi Volume (Satuan Terkecil)</label>
            <v-text-field
              outlined
              dense
              v-model="form_komoditas.volume_terkecil"
              :suffix="form_komoditas.satuan_terkecil"
              :rules="[
                (value) => {
                  return genericRequiredRule(value, 'Realisasi Volume (Satuan Terkecil)');
                },
                (value) => {
                  return this.handleCheckVolumeTerkecil(value);
                },
              ]"
            />
          </div>
          <div class="col-md-6">
            <label class="fs-14 mb-2">Realisasi Harga Satuan Terbesar (Mata Uang)</label>
            <v-text-field
              outlined
              dense
              v-model="form_komoditas.hargasatuan"
              :rules="[
                (value) => {
                  return genericRequiredRule(value, 'Realisasi Harga Satuan Terbesar (Mata Uang)');
                },
              ]"
            />
          </div>
          <div class="col-md-6">
            <label class="fs-14 mb-2">Nilai Sesuai PIB</label>
            <v-text-field
              outlined
              dense
              v-model="form_komoditas.nilai"
              :rules="[
                (value) => {
                  return genericRequiredRule(value, 'Nilai Sesuai PIB');
                },
              ]"
            />
          </div>
          <div class="col-md-6">
            <label class="fs-14 mb-2">Mata Uang dalam PIB</label>
            <v-autocomplete
              :items="list_kurs"
              item-text="uraikurs"
              item-value="kdkurs"
              outlined
              dense
              v-model="form_komoditas.kurs_pib"
              :rules="[
                (value) => {
                  return genericRequiredRule(value, 'Mata Uang dalam PIB');
                },
              ]"
            />
          </div>
          <div class="col-md-6">
            <label class="fs-14 mb-2">File PIB</label>
            <div class="d-flex gap-3">
              <v-autocomplete
                :items="list_dokumen"
                item-text="document_number"
                item-value="upload_id"
                outlined
                dense
                v-model="form_komoditas.upload_id"
                :rules="[
                  (value) => {
                    return genericRequiredRule(value, 'File PIB');
                  },
                ]"
              />
              <HandleDokumenForm iconOnly />
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <v-btn class="btn btn-primary" type="submit" :loading="isLoading">
            <i class="fa-solid fa-plus"></i>
            Tambah
          </v-btn>
        </div>
      </div>
    </v-form>

    <hr />
    <br />
    <div class="card">
      <div class="card-header fs-14 fw-medium py-3">Data Realisasi yang Belum Terkirim</div>
      <div class="card-body">
        <div class="d-flex justify-content-end gap-3 my-5">
          <v-text-field
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari..."
            style="max-width: 300px"
            v-model="optionsTable.search"
            hide-details="auto"
          />
        </div>
        <v-data-table
          :headers="headers"
          group-by="seri"
          :items="data_realisasi_not_sent"
          :options.sync="optionsTable"
          :search="optionsTable.search"
          class="table-striped"
        >
          <template v-slot:[`item.kuota`]="{ item }"> {{ item.kuota }} ({{ item.satuan }}) </template>
          <template v-slot:[`item.kuota_terkecil`]="{ item }">
            {{ item.kuota_terkecil }} ({{ item.satuan_terkecil }})
          </template>
          <template v-slot:[`item.volume`]="{ item }"> {{ item.volume }} ({{ item.satuan }}) </template>
          <template v-slot:[`item.volume_terkecil`]="{ item }">
            {{ item.volume_terkecil }} ({{ item.satuan_terkecil }})
          </template>
          <template v-slot:[`item.nilai`]="{ item }"> {{ item.nilai }} ({{ item.kurs_pib }}) </template>
          <template v-slot:[`item.upload_id`]="{ item }">
            <a :href="linkDownload(item.upload_id)" target="_blank">
              <v-btn class="btn btn-outline-primary" small>
                <i class="fa-solid fa-download"></i>
              </v-btn>
            </a>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small class="btn btn-outline-primary" v-bind="attrs" v-on="on">
                  <span class="fw-light">Action</span>
                  <i class="fa-solid fa-chevron-down small"></i>
                </v-btn>
              </template>
              <v-list style="min-width: 200px">
                <v-list-item @click="handleDelete(item.id)">
                  <v-list-item-title class="text-primary text-opacity-75">
                    <i class="fa-regular fa-trash-can small mr-2"></i>
                    <span class="small">Hapus</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationRules } from "@/mixins/validation-rules.js";
import Swal from "sweetalert2";

export default {
  name: "BarangFormTambah",
  mixins: [ValidationRules],
  components: {
    HandleDokumenForm: () => import("@/components/organisms/handle-dokumen-form"),
  },
  data() {
    return {
      headers: [
        { text: "Seri", value: "seri" },
        { text: "HS Code", value: "hs" },
        { text: "Uraian", value: "uraian" },
        { text: "Negara Asal", value: "negara_asal" },
        { text: "Pelabuhan Tujuan", value: "pelabuhan_tujuan" },
        {
          text: "Volume Sesuai Kuota (Dalam satuan Besar) /Satuan",
          value: "kuota",
        },
        {
          text: "Volume Sesuai Kuota (Dalam satuan Kecil) /Satuan",
          value: "kuota_terkecil",
        },
        {
          text: "Realisasi (dalam satuan besar) /Satuan",
          value: "volume",
        },
        {
          text: "Realisasi (dalam satuan kecil) /Satuan",
          value: "volume_terkecil",
        },
        { text: "Nilai Sesuai PIB /Kurs", value: "nilai" },
        { text: "File", value: "upload_id" },
        { text: "Action", value: "action", sortable: false },
      ],
      optionsTable: {
        page: 1,
        itemsPerPage: 5,
        search: "",
      },
      baseUrl: "https://api.batam-itinventory.com",
      linkDownload: (upload_id) => `${this.baseUrl}/documents/${upload_id}.pdf`,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.realisasi.isLoading;
    },
    komoditas() {
      return this.$store.state.realisasi.report.komoditas;
    },
    list_dokumen() {
      return this.$store.state.dokumen.reports;
    },
    list_kurs() {
      return this.$store.state.kursMataUang.reports;
    },
    form_komoditas: {
      get() {
        return this.$store.state.realisasi.form_komoditas;
      },
      set(value) {
        this.$store.commit("SET_FORM_KOMODITAS_REALISASI", value);
      },
    },
    check_stock() {
      return this.$store.state.realisasi.check_stock;
    },
    data_realisasi_not_sent() {
      return this.$store.state.realisasi.data_realisasi_not_sent;
    },
  },
  methods: {
    handleCheckVolume(value) {
      let komoditas = this.komoditas.find((item) => item.seri === this.form_komoditas.seri);

      if (+value > +this.check_stock.sisa_stock || +value > +komoditas.kuota) {
        return "Volume tidak boleh melebihi sisa kuota";
      }
      return true;
    },
    handleCheckVolumeTerkecil(value) {
      let komoditas = this.komoditas.find((item) => item.seri === this.form_komoditas.seri);

      if (+value > +this.check_stock.sisa_stock_terkecil || +value > +komoditas.kuota_terkecil) {
        return "Volume tidak boleh melebihi sisa kuota";
      }
      return true;
    },
    calculateVolumeTerkecil() {
      let komoditas = this.komoditas.find((item) => item.seri === this.form_komoditas.seri);

      let percentage = this.form_komoditas.volume / komoditas.kuota;
      let volume_terkecil = komoditas.kuota_terkecil * percentage;

      this.form_komoditas.volume_terkecil = volume_terkecil;
    },
    async handleChangeKomoditas(value) {
      let komoditas = this.komoditas.find((item) => item.seri === value);
      await this.$store.commit("SET_FORM_KOMODITAS_REALISASI_DEFAULT", komoditas);

      await this.$store.dispatch("CheckStockRealisasi", {
        id_ijin: this.$route.params.id_ijin,
        seri: value,
      });
    },
    handleDelete(id) {
      Swal.fire({
        title: "Apakah Anda yakin?",
        text: "Data realisasi akan dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("DeleteRealisasiById", id).then((val) => {
            if (val) {
              this.$store.dispatch("GetDataRealisasiNotSent", this.$route.params.id_ijin);
            }
          });
        }
      });
    },
    handleSubmit() {
      if (this.$refs.initialForm.validate()) {
        this.$store.dispatch("AddRealisasiKomoditi").then((val) => {
          if (val) {
            this.$store.dispatch("GetDataRealisasiNotSent", this.$route.params.id_ijin);
            this.$store.commit("RESET_FORM_KOMODITAS_REALISASI");
            this.$store.commit("RESET_CHECK_STOCK_REALISASI");
            this.$refs.initialForm.reset();
          }
        });
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("RESET_FORM_KOMODITAS_REALISASI");
    this.$store.commit("RESET_CHECK_STOCK_REALISASI");
  },
  created() {
    this.$store.dispatch("GetDataRealisasiNotSent", this.$route.params.id_ijin);

    this.$store.dispatch("GetKursMataUang");
    this.$store.dispatch("GetDokumen");
  },
};
</script>
